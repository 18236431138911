import React from 'react';
import {Helmet} from 'react-helmet'

const PrivacyPolicyPage = () => {
    return (
        <div id="privacy-policy">
        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy Policy | Bownbee Canada</title>
            <meta name="description" content="This Privacy Policy relates solely to the information collection and use practices of our web site located at https://www.bownbee.ca" />
            <meta name="keywords" content="Indian Ethinic wear, North America,Bownbee, Bownbeecanada, Bownbeeca" />

            <link rel="canonical" href="https://www.bownbee.ca/about-us" />
        </Helmet>
            <div className="breadcrumb h-8 bg-gray-100 grid justify-items-center content-center">Privacy Policy
            </div>
            <div className="lg:container lg:mx-auto p-10">
                <p>This Privacy Policy relates solely to the information collection and use practices of our web site located at https://www.bownbee.ca (this “Website”). We recognize that many visitors and users of this Website are concerned about the information they provide to us, and how we treat that information. This Privacy Policy, which may be updated from time to time, has been developed to address those concerns.</p><br/>
                <p className="font-extrabold">BY ACCESSING OR USING OUR WEBSITE OR BY PROVIDING YOUR INFORMATION, YOU HEREBY ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREED TO BE BOUND BY ALL THE TERMS OF THIS PRIVACY POLICY AND OUR WEBSITE TERMS OF USE. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE EXIT THIS PAGE AND DO NOT ACCESS OR USE THE WEBSITE.</p><br/>
                <p className="font-bold">1. Changes to Privacy Policy</p>
                <p>We review our Privacy Policy from time to time, and we may make periodic changes to the policy in connection with that review. The revisions in Privacy Policy will be effective immediately upon being posted on the Website. Therefore, you may wish periodically review this page to make sure you have the latest version. Your continued use of the Website after the effectiveness of such revisions will constitute your acknowledgment and acceptance of the terms of the revised Privacy Policy.</p><br/>
                <p className="font-bold">2. Types of Information Collected and Uses of Collected Information</p>
                <p>We collect two types of information about our Website Users: Personally Identifiable Information and Non-Personally Identifiable Information.

                <span className="font-bold">Personally Identifiable Information:</span> Personally Identifiable Information is information that identifies a specific End User. When you engage in certain activities on the Website, such as creating an account, ordering a product or service from us, submitting content and/or posting content in discussion forums, filling out a survey, posting a review, requesting information about our services, applying for a job (collectively, “Identification Activities”), we may ask you to provide certain information about yourself. It is optional for you to engage in an Identification Activity. If you elect to engage in an Identification Activity, however, we may ask you to provide us with certain personal information about yourself, such as your first and last name, mailing address (including PIN code), email address, telephone number and date of birth. When you order products, we may also ask you to provide us with your credit card number, expiration date and authentication codes or related information. Depending on the activity, some of the information we ask you to provide is identified as mandatory and some is identified as voluntary. If you do not provide the mandatory information for a particular activity that requires it, you will not be permitted to engage in that activity.</p>
                <p>We use Personally Identifiable Information to provide products to you, enhance the operation of the Website, improve our marketing and promotional efforts, analyse Website use, improve our product offerings, and to provide you a better experience. For example, if you send our customer service an email we may use your comments and feedback to tell others about our services, and may post your comment in our marketing materials or on our Website. Also, if you use our Website to send information or a product to another person, we may store your personal information, and the personal information of any recipient. We may use that other person’s contact information to allow him or her to view and accept your gift or to allow the recipient to access the information you sent. We may also use Personally Identifiable Information to troubleshoot, resolve disputes, accomplish administrative tasks, contact you, enforce our agreements with you, including our Website Terms of Use and this Privacy Policy, comply with applicable law, and cooperate with law enforcement activities.</p>
                <p><span className="font-bold">Non-Personally Identifiable Information:</span> Non-Personally Identifiable Information is information that does not identify a specific End User. This type of information may include things like the Uniform Resource Locator (“URL”) of the website you visited before coming to our Website, the URL of the website you visit after leaving our Website, the type of browser you are using and your Internet Protocol (“IP”) address.</p>
                <p>We use Non-Personally Identifiable Information to troubleshoot, administer the Website, analyze trends, gather demographic information, comply with applicable law, and cooperate with law enforcement activities.</p><br/>
                <p className="font-bold">3. Release of Personally Identifiable Information</p>
                <p>We will not share your Personally Identifiable Information with other parties except as provided below: We may share your information with Authorized Third Party Service Providers. We provide some of our services and products through third parties. These “Third Party Service Providers” perform functions on our behalf, like sending out and distributing our administrative and promotional emails. We may share your Personally Identifiable Information with such Service Providers to deliver packages, send email, provide marketing assistance, provide search results and links, process credit card payments, operate the Website, troubleshoot, and provide customer service.
                We may also disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p><br/>
                <p className="font-bold">4. Release of Non-Personally Identifiable Information</p>
                <p>We may disclose or share Non-Personally Identifiable Information with Partners, Affiliates and Advertisers. We may share aggregated demographic information (which does not include any Personally Identifiable Information) with “Third Party Advertisers” or “Third Party Advertising Companies”.</p>
                <p>We also use Third Party Service Providers to track and analyze Non-Personally Identifiable usage and volume statistical information from our Users to administer our Website and constantly improve its quality. We may also publish this information for promotional purposes or as a representative audience for Advertisers. Please note that this is not Personally Identifiable Information, only general summaries of the activities of our Users. Such data is collected on our behalf, and is owned and used by us.</p><br/>
                <p className="font-bold">5. Updating Information</p>
                <p>You will have the ability to access and edit the Personally Identifiable Information you provide us. You may change any of your Personally Identifiable Information by accessing through your login and password.</p>
                <p>We would request you to promptly update your Personally Identifiable Information if it changes.</p><br/>
                <p className="font-bold">6. Data Tracking</p>
                <p><span className="font-bold">Cookies.</span> “Cookies” are small pieces of information that are stored by your browser on your computer’s hard drive. The use of cookies is very common on the Internet and our Website´s use of cookies is similar to that of other reputable online companies.Cookies will be used to customize your experience with the Website. We use cookies to save you time while using the Website, helps us identify who you are, and track and target User interests in order to provide a customized experience. Cookies also allow us to collect Non-Personally Identifiable Information from you, like which pages you visited and what links you clicked on. Use of this information helps us to create a more user-friendly experience for all visitors. In addition, we may use Third Party Advertising Companies to display advertisements on our Website. Most browsers automatically accept cookies, but you may be able to modify your browser settings to decline cookies. Please note that if you decline or delete these cookies, some parts of the Website may not work properly.Additionally, you may encounter “cookies” or other similar devices on certain pages of the website that are placed by third parties. We do not control the use of cookies by third parties.</p>
                <p><span className="font-bold">Other Tracking Devices.</span> We may use other industry standard technologies like pixel tags and web beacons to track your use of our Website pages and promotions, or we may allow our Third Party Service Providers to use these devices on our behalf. Pixel tags and web beacons are tiny graphic images placed on certain pages on our Website, or in our emails that allow us to determine whether you have performed a specific action. When you access these pages or open or click an email, pixel tags and web beacons generate a Non-Personally Identifiable notice of that action. Pixel tags allow us to measure and improve our understanding of visitor traffic and behaviour on our Website, as well as give us a way to measure our promotions and performance. We may also utilize pixel tags and web beacons provided by our Affiliates and/or Marketing Partners for the same purposes.</p><br/>
                <p className="font-bold">7. Security of Information</p>
                <p>We take appropriate precautions to protect the security of Personally Identifiable Information. You can access your Personally Identifiable Information on our Website through your login and password. We recommend that you do not share your password with anyone. In addition, your Personally Identifiable Information resides on a secure server that only selected personnel and contractors have access to. We encrypt certain sensitive information using Secure Socket Layer (SSL) technology to ensure that your Personally Identifiable Information is safe as it is transmitted to us.</p>
                <p>However, no data transmission over the Internet can be guaranteed to be completely secure. Accordingly, we cannot ensure or warrant the security of any information that you transmit to us, so you do so at your own risk.
                </p><br/>
                <p className="font-bold">8. Privacy Policies of Third Party Websites</p>
                <p>This Privacy Policy only addresses the use and disclosure of information we collect from you. Other websites that may be accessible through this Website have their own privacy policies and data collection, use and disclosure practices. If you link to any such website, we urge you review the website’s privacy policy. We are not responsible for the policies or practices of third parties.</p><br/>
                <p className="font-bold">9. Miscellaneous Privacy Issues</p>
                <p><span className="font-bold">Children.</span> Minors under the age of 18 are not supposed to use the Website. We do not collect or maintain information from anyone known to be under the age of 18, and no part of the Website is designed to attract anyone under the age of 18. If you are under 18 and still want to buy an product, you may use bownbee only with the involvement of a parent or guardian.</p>
                <p><span className="font-bold">Public Areas.</span> We may provide areas on our Website where you can publicly post information about yourself, can communicate with others, or can review products. This information may be accessible by other consumers and companies and may appear on other websites or web searches, and therefore this information could be read, collected, and used by others.</p><br/>
                <p className="font-bold">10. OPT-OUT of further usage of PERSONAL INFORMATION</p>
                <p>If you are no longer interested in receiving e-mail announcements and other marketing information from us, or you want us to remove any PII that we have collected about you, please e-mail your request to <a href="mailto:bownbeecanada@gmail.com">bownbeecanada@gmail.com</a></p><br/><br/>
                <p className="font-bold">How to Contact Us</p>
                <p>If you have any questions or comments about this Privacy Notice, or if you would like us to update information we have about you or your preferences, please contact us by email at <a href="mailto:bownbeecanada@gmail.com">bownbeecanada@gmail.com</a></p>
            </div>
        </div>
    );
};

export default PrivacyPolicyPage;